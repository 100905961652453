import Cookies from 'js-cookie'
import { defineStore } from 'pinia'
import { version as clientVersion } from '../../package.json'
import { useNotifications, INFO, INFINITE } from './notifications.js'
import axios from '../axios.js'

let notificationId = null
export const useStagetimer = defineStore('stagetimer', {
  state: () => ({
    serverVersion: null,
    serverTimeDiff: 0,
  }),
  getters: {
    getNow: state => () => Date.now() - state.serverTimeDiff,
    // getNow: state => ({ delay = false } = {}) => {
    //   return Date.now() - state.serverTimeDiff + (delay ? state.clientDelay : 0)
    // },
    fullPath: state => {
      const campaign = Cookies.get('mtm_campaign')
      const pathParts = state.route?.fullPath.split('?') || []
      const params = new URLSearchParams(pathParts?.[1])
      if (campaign) params.set('utm_campaign', campaign)
      return pathParts[0] + (String(params) ? '?' + String(params) : '')
    },
  },
  actions: {
    setServerVersion (payload) {
      const { version: serverVersion } = payload
      if (!serverVersion) return
      if (clientVersion !== serverVersion && !notificationId) {
        const notifications = useNotifications()
        notificationId = notifications.trigger({
          type: INFO,
          title: 'Updates available',
          text: `Reload page to apply version ${serverVersion}`,
          btnLabel: 'Reload Page',
          btnAction: () => location.reload(),
          duration: INFINITE,
        })
      }
      this.serverVersion = serverVersion
    },
    setServerTime (time) {
      this.serverTimeDiff = Date.now() - time
    },
    async routeChange ({ to, from }) {
      this.route = to
      await axios.post('/aux/tp', { path: this.fullPath, urlref: from.fullPath })
    },
  },
})
