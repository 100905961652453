import axios from 'axios'
import config from '../shared/configImporter.js'

/**
 * Instantiate Axios instances
 */
export const serverApi = axios.create({
  baseURL: import.meta.env.PUBLIC_API_ROOT,
  timeout: 30000,
})
export const auxApi = serverApi.create({ baseURL: config.functions.AUX })
export const billingApi = serverApi.create({ baseURL: config.functions.BILLING })
export const userApi = serverApi.create({ baseURL: config.functions.USER })
export const teamApi = serverApi.create({ baseURL: config.functions.TEAM })

/**
 * Inject the firebase auth instance
 * Is left empty in electron version
 */
let firebaseAuth
export function injectFirebaseAuth (authInstance) {
  firebaseAuth = authInstance
}

/**
 * Add request interceptor to attach the firebase auth token and browser timezone
 */
async function useAuth (config) {
  let idToken
  if (firebaseAuth) {
    idToken = await firebaseAuth.currentUser?.getIdToken()
  } else if (import.meta.env.PUBLIC_CONFIG_ENV === 'ELECTRON') {
    idToken = 'ELECTRON'
  }
  if (idToken) config.headers['Authorization'] = idToken
  return config
}
serverApi.interceptors.request.use(useAuth)
billingApi.interceptors.request.use(useAuth)
userApi.interceptors.request.use(useAuth)
teamApi.interceptors.request.use(useAuth)

/**
 * Measure ping
 */
// instance.interceptors.request.use(config => {
//   config.meta = config.meta || {}
//   config.meta.requestStartedAt = Date.now()
//   return config
// })

// instance.interceptors.response.use(res => {
//   res.config.meta.ping = Date.now() - res.config.meta.requestStartedAt
//   console.info('Ping', Date.now() - res.config.meta.requestStartedAt)
//   return res
// })

export default serverApi
