import RoomParent from '../views/room/Parent.vue'
import { validatePid, awaitUser } from './middleware.js'
import { ERROR } from '../store/notifications.js'

export default [
  //
  // Rooms (App Core)
  // ^/r
  //
  {
    path: '/r/',
    name: 'roomParent',
    redirect: { name: 'roomGenerate' },
    component: RoomParent,
    meta: {
      app: true,
      background: 'dark',
      title: 'Loading Room',
      loadingOverlay: false,
    },
    beforeEnter: [awaitUser],
    children: [
      {
        path: 'generate/',
        name: 'roomGenerate',
        meta: { title: 'Generate Room' },
        component: () => import('../views/room/Generate.vue'),
      },
      {
        path: 'invalid/',
        name: 'roomInvalid',
        meta: { title: 'Invalid Room' },
        component: () => import('../views/room/InvalidPid.vue'),
      },
      {
        path: 'kicked/',
        name: 'roomKicked',
        meta: { title: 'Kicked from Room' },
        component: () => import('../views/room/Kicked.vue'),
      },
      {
        path: ':roomPid/',
        component: () => import('../views/room/Room.vue'),
        props: true,
        beforeEnter: [validatePid],
        children: [
          {
            path: '',
            name: 'viewer',
            meta: {
              notifications: [ERROR],
              background: 'transparent',
              hideConsentBanner: true,
            },
            props: true,
            component: () => import('../views/room/Viewer.vue'),
          },
          {
            path: 'controller/',
            name: 'controller',
            props: true,
            component: () => import('../views/room/Controller.vue'),
          },
          {
            path: 'agenda/',
            name: 'agenda',
            meta: {
              notifications: [ERROR],
              hideConsentBanner: true,
            },
            props: true,
            component: () => import('../views/room/Agenda.vue'),
          },
          {
            path: 'moderator/',
            name: 'moderator',
            meta: {
              hideConsentBanner: true,
            },
            props: true,
            component: () => import('../views/room/Moderator.vue'),
          },
        ],
      },
    ],
  },

  //
  // Submit question links
  // ^/q
  //
  {
    path: '/q/:roomId/',
    name: 'submit-questions',
    props: true,
    component: () => import('../views/room/SubmitQuestions.vue'),
  },
]
