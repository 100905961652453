<template>
  <footer
    class="relative text-left font-light"
    :class="{ 'bg-gradient-to-b from-neutral-300 to-neutral-100': !props.hideCta }"
  >
    <svg v-if="!props.hideCta" viewBox="0 40 1440 160" class="absolute top-0 hidden w-full md:block">
      <path fill="#fff" d="M0 192l120-5.3c120-5.7 360-15.7 600-16 240 .3 480 10.3 600 16l120 5.3V0H0z" />
    </svg>

    <section
      v-if="!props.hideCta"
      class="relative container max-w-screen-xl mx-auto bg-gradient-to-r from-emerald-500 to-emerald-300 shadow-2xl flex flex-col md:flex-row items-center text-slate-900 rounded-none lg:rounded-3xl z-10 mb-12 md:mb-24 px-4 md:px-12"
    >
      <div class="text-center md:text-left md:w-3/5 lg:w-1/2 space-y-6 py-12">
        <h2
          class="text-3xl font-bold md:text-4xl xl:text-5xl"
        >
          Ready to simplify timing?
        </h2>

        <ul class="space-y-2 font-medium">
          <li><FaIcon :icon="faCheck" class="mr-2" /> Set up your room</li>
          <li><FaIcon :icon="faCheck" class="mr-2" /> Share the link</li>
          <li><FaIcon :icon="faCheck" class="mr-2" /> Start the show</li>
        </ul>
        <a
          class="group btn bg-slate-800 hover:bg-white text-white hover:text-slate-800 text-xl font-medium rounded-lg py-4 px-8 lg:px-12"
          href="/r/generate/"
          data-label="generate-room-button"
        >
          <span class="mr-2">Try Stagetimer for free</span>
          <FaIcon
            :icon="faArrowRight"
            size="xs"
            class="group-hover:translate-x-2 transition-transform"
          />
        </a>
        <p class="text-sm text-teal-800 font-light !mt-2">No credit card or signup required</p>
      </div>
      <div class="md:w-2/5 lg:w-1/2">
        <FooterIllustration class="w-full max-w-sm mx-auto" />
      </div>
    </section>

    <section
      class="container max-w-screen-xl grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 relative z-10 mx-auto px-4 md:px-12"
      :class="containerClass"
    >

      <!-- Logo and Blurp -->
      <div class="md:col-span-3 lg:col-span-2 leading-relaxed text-neutral-600">
        <SiteLogo class="block text-xl mb-4" underline />
        <p class="mb-3">
          A free speaker timer that runs entirely in the browser.<br>Any internet device can use it.
        </p>
        <p v-if="props.config" class="text-sm mb-3">
          <span>Version {{ props.config.version }}</span>
          <span class="px-2">&middot;</span>
          <a class="hover:underline" href="/changelog/">Changelog</a>
          <span class="px-2">&middot;</span>
          <a class="hover:underline" href="https://stats.uptimerobot.com/jJ140cjvEB" rel="noopener">Platform Status</a>
        </p>
      </div>

      <!-- Product -->
      <div class="grid-span-1">
        <FooterNav
          class="mb-6"
          title="Product"
          :links="{
            'Features': '/features/',
            'Desktop App': '/desktop-app/',
            'Pricing': '/pricing/',
            'Public Roadmap': 'https://lhermann.notion.site/744c5df925d248129f2bad199433cff9?v=e8dacc1a65d9487ab053335425b2af55',
          }"
          :pathname="props.pathname"
        />
        <FooterNav
          class="mb-6"
          title="Compare"
          :links="{
            'Why Stagetimer?': '/why-choose-stagetimer/',
            'Shoflo Alternative': '/shoflo-alternative/',
            'ProPresenter Alternative': '/propresenter-alternative/',
            'Cuez Alternative': '/cuez-alternative/',
          }"
          :pathname="props.pathname"
        />
      </div>

      <!-- Use Cases -->
      <FooterNav
        class="grid-span-1"
        title="Use Cases"
        :links="{
          'Presentations': '/use-cases/online-presentation-timer/',
          'Meetings': '/use-cases/meeting-timer/',
          'Livestreams': '/use-cases/online-timer-for-livestreams/',
          'Video Productions': '/use-cases/timer-for-video-productions/',
          'Events': '/use-cases/event-production-timer/',
          'Conferences': '/use-cases/conference-and-speaker-timer/',
          'Broadcasting': '/use-cases/timer-for-broadcasting/',
          'Education': '/use-cases/online-timer-for-education/',
          'Esports': '/use-cases/timer-for-esports/',
          'More...': '/#use-cases'
        }"
        :pathname="props.pathname"
      />

      <!-- Resources -->
      <FooterNav
        class="grid-span-1"
        title="Resources"
        :links="{
          'Documentation': '/docs/',
          'Blog': '/blog/',
          'FAQ': '/faq/',
          'What experts say': '/media/',
          'Testimonials': '/testimonials/',
        }"
        :pathname="props.pathname"
      />

    </section>

    <!-- Legal -->
    <SiteFooterLegal />

    <section
      v-if="!props.hideGoTimers"
      class="bg-neutral-800 text-neutral-500 text-sm"
    >
      <div
        class="container max-w-screen-xl space-y-2 mx-auto px-4 md:px-12 py-10"
        :class="props.containerClass"
      >
        <p class="flex flex-wrap justify-start md:justify-center gap-4">
          <span class="text-neutral-600">Instant Timer:</span>
          <a href="/go/1-minute/" class="hover:underline">
            1 Minute Timer
          </a>
          <a href="/go/5-minutes/" class="hover:underline">
            5 Minute Timer
          </a>
          <a href="/go/10-minutes/" class="hover:underline">
            10 Minute Timer
          </a>
          <a href="/go/30-minutes/" class="hover:underline">
            30 Minute Timer
          </a>
          <a href="/go/1-hour/" class="hover:underline">
            1 Hour Timer
          </a>
          <a href="/go/clock/" class="hover:underline">
            Clock
          </a>
        </p>
        <p class="flex flex-wrap justify-start md:justify-center gap-4">
          <span class="text-neutral-600">Tools:</span>
          <a href="https://rundownstudio.app/" rel="noopener" target="_blank" class="hover:underline">
            Rundown Studio
          </a>
          <a href="https://reactions.stagetimer.io/" rel="noopener" target="_blank" class="hover:underline">
            Audience Reactions
          </a>
        </p>
      </div>
    </section>
  </footer>
</template>

<script setup>
import SiteLogo from './components/SiteLogo.DEPRECATED.vue'
import FooterNav from './components/FooterNav.vue'
import SiteFooterLegal from './SiteFooterLegal.vue'
import FaIcon from './components/FaIcon.vue'
import FooterIllustration from './components/FooterIllustration.vue'
import { faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons'

const props = defineProps({
  pathname: { type: String, default: '/' },
  containerClass: { type: String, default: '' },
  config: { type: Object, default: undefined },
  hideCta: Boolean,
  hideGoTimers: Boolean,
})
</script>

<style scoped>
li > a {
  @apply text-neutral-600 hover:underline;
}
</style>
