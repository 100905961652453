import { timerBehaviors, todFormats, countdownFormats } from '@stagetimerio/shared'
import _isEqual from 'lodash/isEqual'

let cachedMoment = null

/**
 * interface Moment = {
 *   timerId: ObjectId
 *   running: Boolean
 *   deadline: Date
 *   kickoff: Date
 *   lastStop: Date
 *   total: Number (milliseconds)
 *   left: Number (milliseconds)
 *   time: Number (milliseconds)
 *   yellow: Number (milliseconds)
 *   red: Number (milliseconds)
 *   title: String
 *   todFormat: String (see @stagetimerio/shared -> todFormats)
 *   real: {
 *     total: Number
 *     left: Number
 *     yellow: Number
 *     red: Number
 *   },
 * }
 */

/**
 * Creates the moment from timeset and timer. The moment is the authoritative data for the current countdown and
 * updates every 150ms when the timer is running.
 *
 * @param  {Timeset} timeset
 * @param  {Timer} timer
 * @param  {Date|Number} now
 * @param  {string} behavior
 * @param  {string} todFormat
 * @param  {string} countdownFormat
 * @return {Moment}
 */
export default function createMoment (
  timeset,
  timer,
  now = Date.now(),
  behavior = timerBehaviors.STOP,
  todFormat = todFormats.AUTO,
  countdownFormat = countdownFormats.DEFAULT_COUNTDOWN_FORMAT,
) {
  const tenMin = 60000
  if (now <= 0) now = Date.now()

  // Calculate real 'total' (round 10 ms due to inaccuracies)
  const realTotal = timeset.deadline ? timeset.deadline - timeset.kickoff : tenMin

  // Calculate real 'left'
  let realLeft
  if (!timeset.deadline) realLeft = tenMin
  else if (timeset.running) realLeft = timeset.deadline - now
  else realLeft = timeset.deadline - timeset.lastStop

  // Correct edge-case where left > total
  if (realLeft > realTotal) realLeft = realTotal

  // Calculate warped durations if TIME_WARP is active
  let total = realTotal
  let left = realLeft
  let yellow = timer?.yellow || 0
  let red = timer?.red || 0

  if (timeset.deadlineWarped !== null) {
    const warpedTotal = timeset.deadlineWarped - timeset.kickoff

    // Scale factor = how much slower/faster the timer appears to run
    const scaleFactor = warpedTotal / realTotal

    // Scale both total and remaining time by the same factor
    total = warpedTotal
    left = Math.round(realLeft * scaleFactor)
    yellow = Math.round(yellow * scaleFactor)
    red = Math.round(red * scaleFactor)
  }

  // Calculate 'time' with potentially warped values
  const stop = left <= 0 && behavior === timerBehaviors.STOP

  const moment = {
    ...timeset,
    total: floor50(total),
    left: floor50(left),
    countdown: floor50(stop ? 0 : left),
    countup: floor50(stop ? total : total - left),
    yellow: floor50(yellow),
    red: floor50(red),
    title: timer?.showName ? timer?.name : '',
    extra: timer?.showExtra ? timer?.extra : '',
    todFormat,
    countdownFormat,
    // Add real durations for debugging/development
    real: {
      total: floor50(realTotal),
      left: floor50(realLeft),
      yellow: floor50(timer?.yellow || 0),
      red: floor50(timer?.red || 0),
    },
  }

  if (!_isEqual(moment, cachedMoment)) cachedMoment = moment
  return cachedMoment
}

function floor50 (num) {
  return Math.floor(num / 50) * 50
}
