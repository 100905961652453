<template>
  <PageLayout
    class="text-neutral-900"
    :container-class="`${route.meta.containerClass || 'container'} mx-auto px-4 md:px-12 pb-36 min-h-[80vh]`"
    hide-cta
  >

    <!-- Handle error when loading team + subscription -->
    <template v-if="error">
      <div class="max-w-xl mx-auto text-center my-24 space-y-6">
        <template v-if="error?.response?.status === 403">
          <h1 class="text-5xl">403 Permission Denied</h1>
          <p>You don't have permission to access this plan</p>
        </template>
        <template v-else-if="error?.response?.status === 404">
          <h1 class="text-5xl">404 Not Found</h1>
          <p>The plan you are looking for wasn't found.</p>
        </template>
        <template v-else>
          <h1 class="text-5xl">Error</h1>
        </template>
        <ErrorAlert
          :error="error"
          light
        />
      </div>
    </template>

    <template v-else-if="plan">
      <router-view
        :plan="plan"
      />
    </template>
  </PageLayout>
</template>

<script setup>
import ErrorAlert from '../../components/shared/ErrorAlert.vue'
import PageLayout from '../../layouts/Page.vue'
import eventBus from '../../utils/eventBus.js'
import axios from '../../axios.js'
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'

const plan = ref(null)
const error = ref(null)
const route = useRoute()
const props = defineProps({
  entityId: { type: String, required: true },
})

watch(() => props.entityId, loadPlan, { immediate: true })
async function loadPlan (entityId) {
  eventBus.$emit('asyncRouteLoadingStart')
  try {
    const { data } = await axios.get(`/plans/${entityId}`)
    plan.value = data
  } catch (err) {
    error.value = err
  }
  eventBus.$emit('asyncRouteLoadingStop')
}
</script>
