/**
 * Wrapper around localStorage so the app don't crashes if access is denied
 *
 * Error: Uncaught (in promise) DOMException: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
 */

export default {
  available () {
    try {
      window.localStorage.getItem('test')
      return true
    } catch {
      // Assume local storage is not available or access is denied
      return false
    }
  },
  getItem (key) {
    try {
      return window.localStorage.getItem(key)
    } catch {
      return undefined
    }
  },
  setItem (key, value) {
    try {
      window.localStorage.setItem(key, value)
    } catch {
      return undefined
    }
  },
  getJSON (key, defaultValue = null) {
    const value = this.getItem(key)
    if (value === undefined || value === null) return defaultValue
    try {
      return JSON.parse(value)
    } catch {
      return defaultValue
    }
  },
  setJSON (key, value) {
    return this.setItem(key, JSON.stringify(value))
  },
}
