/*!
 * @stagetimerio/server
 * Copyright(c) 2020-2024 Lukas Hermann <lukas@stagetimer.io>
 * All rights reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import Socket from './socket/Socket.js'
import TransportSocketio from './socket/TransportSocketio.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import * as Sentry from '@sentry/vue'
import { initSentry } from './sentry.js'
import { version } from '../package.json'
import firebasePlugin from './plugins/firebasePlugin.js'
import versionCheckPlugin from './plugins/versionCheckPlugin.js'
import emailObfuscatePlugin from './plugins/emailObfuscatePlugin.js'
import FloatingVue from 'floating-vue'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { injectFirebaseAuth } from './axios.js'
import config from '../shared/configImporter.js'
import { initReferrers, initEntrypoint } from '../shared/referrers.js'

// Styles
import './styles/main.css'

// Remove temp meta tags
const tempElements = document.querySelectorAll('[data-temp-meta]')
tempElements.forEach(el => el.remove())

// Floating Vue Theme
const floatingVueTheme = {
  $resetCss: true,
  triggers: ['hover', 'focus'],
  popperTriggers: ['hover', 'focus'],
  autoHide: true,
  placement: 'bottom-start',
  distance: 4,
  overflowPadding: 12,
}

// Initialize Firebase
initializeApp(config.firebase)
injectFirebaseAuth(getAuth())

// Initialize Vue
const app = createApp(App)

// Initialize Sentry
initSentry(Sentry, app, router)

app.use(router)
app.use(createPinia())
app.use(firebasePlugin)
app.use(versionCheckPlugin, { router })
app.use(emailObfuscatePlugin)
app.use(createHead())
app.component('FaIcon', FontAwesomeIcon)
app.use(FloatingVue, {
  themes: {
    light: floatingVueTheme,
    dark: floatingVueTheme,
  },
})

// Do stuff before mount
app.config.globalProperties.$version = version
Socket.init(TransportSocketio)

// Mount Vue
app.mount('#app')

// Add sentry error handler
app.config.errorHandler = (error, _, info) => {
  Sentry.setTag('info', info)
  Sentry.captureException(error)
  if (import.meta.env.DEV) console.error(error)
}

// Initialize stuff after Vue
initReferrers()
initEntrypoint()
