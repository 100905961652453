<template>
  <header
    class="py-6"
    :class="{
      'text-white': dark,
      'text-neutral-800': !dark,
    }"
  >
    <div
      class="container mx-auto px-4 md:px-12 flex items-center justify-between space-x-4 md:space-x-6 hide-duplicate"
      :class="containerClass"
    >
      <SiteLogo
        class="flex-none md:text-xl"
        :dark="dark"
        underline
      />

      <!-- Links for logged in users -->
      <div
        v-if="user.authenticated"
        class="flex-auto hidden md:flex flex-wrap justify-center gap-3"
      >
        <!-- There's now a automaic redirect -->
        <router-link
          class="menu-link"
          :class="{ dark, active: isActive('/team/.+/dashboard/') }"
          :to="{ name: 'dashboard', params: { teamId } }"
        >
          Dashboard
        </router-link>

        <router-link
          class="menu-link"
          :class="{ dark, active: isActive('/team/.+/devices/') }"
          :to="{ name: 'devices', params: { teamId } }"
        >
          <span class="hidden lg:inline">Connected</span> Devices
        </router-link>

        <!-- Billing -->
        <router-link
          class="menu-link"
          :class="{ dark, active: isActive('/team/.+/billing/') }"
          :to="{ name: 'billing', params: { teamId } }"
        >
          Billing
        </router-link>
      </div>

      <AccountButton
        v-if="user.authenticated"
        :team-id="teamId"
        class="flex-none hidden md:block"
        :btn-class="['btn border py-0 px-3 h-10 leading-10', {
          'border-white/40 hover:border-white': dark,
          'border-neutral-300 hover:border-neutral-700': !dark,
        }]"
        :dark="dark"
      />
      <div
        v-else
        class="space-x-4 lg:space-x-6 hidden lg:block"
      >
        <a
          data-label="login-button"
          class="menu-link"
          :class="{ dark, active: isActive('/auth/login/') }"
          href="/auth/login/"
        >
          Sign in
        </a>
        <a
          data-label="signup-button"
          class="btn border py-0 px-3 h-10 leading-10"
          :class="{
            'border-white/40 hover:border-white': dark,
            'border-neutral-300 hover:border-neutral-700': !dark,
            active: isActive('/auth/signup/'),
          }"
          href="/auth/signup/"
        >
          Sign up
        </a>
      </div>

      <button
        class="btn h-10 w-10 p-0 md:hidden"
        :class="{
          'border-white/40 hover:border-white': dark,
          'border-neutral-300 hover:border-neutral-700': !dark,
        }"
        aria-label="Open site menu"
        @click="menuModalOpen = !menuModalOpen"
      >
        <FaIcon :icon="faBars" />
      </button>
    </div>

    <MobileMenu
      v-model:open="menuModalOpen"
      :pathname="pathname"
      :items="[
        { href: `/team/${teamId}/dashboard/`, label: 'Dashboard' },
        { href: `/team/${teamId}/devices/`, label: 'Connected Devices' },
        { href: `/team/${teamId}/billing/`, label: 'Billing' },
        { href: '/user/settings/', label: 'Settings' },
        { href: '/docs/', label: 'Documentation' },
        { href: '/auth/signout/', label: 'Logout' },
      ]"
    />
  </header>
</template>

<script setup>
import SiteLogo from './shared/SiteLogo.vue'
import AccountButton from './shared/AccountButton.vue'
import MobileMenu from '../../shared/components/MobileMenu.vue'
import FaIcon from '../../shared/components/FaIcon.vue'
import { useUser } from '../store/user.js'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { useRoute } from 'vue-router'
import { ref, computed } from 'vue'

const route = useRoute()
const user = useUser()
const props = defineProps({
  pathname: { type: String, default: '/' },
  containerClass: { type: String, default: '' },
  dark: Boolean,
})
const menuModalOpen = ref(false)
const teamId = computed(() => route.params.teamId || user.uid)

function isActive (path) {
  const regex = new RegExp('^' + path)
  return regex.test(props.pathname)
}
</script>

<style scoped>
.menu-link {
  @apply whitespace-nowrap rounded py-1 px-3;
  text-decoration-color: transparent;
}
.menu-link:hover,
.menu-link.active {
  text-decoration: underline;
  text-decoration-color: theme('colors.teal.500');
}
.menu-link.dark:hover,
.menu-link.dark.active {
  text-decoration-color: theme('colors.white');
}
.dropdown-link {
  @apply rounded transition-colors;
}
.dropdown-link:hover {
  @apply bg-neutral-200;
}
/* Astro renders the header twice during dev */
.hide-duplicate ~ .hide-duplicate {
  @apply hidden;
}
</style>
