import { commitReferrers } from '../../shared/referrers.js'
import { useUser } from '../store/user.js'
import { updateUserId } from '../sentry.js'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import * as PaddleService from '../../shared/PaddleService.js'
import localStorage from '../../shared/localStorage.js'

const verbosity1 = parseInt(localStorage.getItem('stagetimer:verbosity')) >= 1

/**
 * Note: Initiate Paddle when we have a authenticated user to activate Paddle Retain and Engagement.
 */
export default {
  async install () {
    const auth = getAuth()

    const userStore = useUser()

    onAuthStateChanged(auth, (user) => {
      if (verbosity1) console.info('[firebasePlugin] onAuthStateChanged', user?.uid)
      userStore.onFirebaseAuth(user)
      updateUserId(user?.uid)
      if (user?.uid) {
        PaddleService.init()
        commitReferrers(user.email)
      }
    })
  },
}
