import { initializePaddle } from '@paddle/paddle-js'
import localStorage from './localStorage.js'
import config from './configImporter.js'
import axios from 'axios'

const verbosity1 = parseInt(localStorage.getItem('stagetimer:verbosity')) >= 1

// Store the callbacks for Paddle events.
const callbacks = {}

// Keep track of the Paddle instance.
let paddleInstancePromise = null

/**
 * Initializes the Paddle instance with the given environment and token.
 * This function is idempotent, meaning that calling it multiple times will not result in multiple initializations.
 *
 * @idempotent
 * @returns {Promise<Paddle>} A promise that resolves to the Paddle instance.
 */
export async function init () {
  if (verbosity1) console.info('[PaddleService] init()')
  // Return the existing promise if Paddle is already being or has been initialized.
  if (paddleInstancePromise) return paddleInstancePromise

  // Define the environment and token from the import.meta.env variables.
  const environment = config.paddleEnvironment
  const token = import.meta.env.PUBLIC_PADDLE_TOKEN

  // Define the event callback function.
  const eventCallback = ({ name, data }) => {
    if (callbacks[name]) {
      callbacks[name](name, data)
    }
  }

  // Create a promise to initialize Paddle, store it, and return it.
  paddleInstancePromise = initializePaddle({
    environment,
    token,
    eventCallback,
    pwCustomer: {},
    checkout: {
      settings: {
        variant: 'multi-page',
        showAddTaxId: true,
      },
    },
  })
  if (verbosity1) console.info('[PaddleService] Paddle init done')
  return paddleInstancePromise
}

/**
 * Inititalize Paddle with the customerId if available.
 * Used for Paddle Retain and ProfitWell.
 * @param  {Team} team
 * @return {void}
 */
export async function initTeam (team) {
  if (!team?.paddleCustomerId) return
  const paddle = await init()
  paddle.Update({
    pwCustomer: {
      id: team.paddleCustomerId,
    },
  })
  if (verbosity1) console.info(`[Paddle] init customer: ${team.paddleCustomerId.substring(0, 15)}***`)
  await sendProfitwellEvent(team.paddleCustomerId)
}

/**
 * Retrieves the instantiated Paddle instance.
 * This function is idempotent.
 *
 * @returns {Promise<Paddle>} A promise that resolves to the Paddle instance.
 */
export async function get () {
  // Ensure the Paddle instance is initialized before returning it.
  // If not initialized, init() will be called to create the instance.
  return await init()
}

/**
 * Registers a callback function for a specific Paddle event.
 *
 * @param {string} event - The name of the Paddle event.
 * @param {function} callback - The callback function to execute when the event is triggered.
 */
export function on (event, callback) {
  callbacks[event] = callback
}

async function sendProfitwellEvent (customerId) {
  if (!config.profitwellPublicToken) return
  if (verbosity1) console.info('[Paddle] sending profitwell event')
  return await axios.get('https://www2.profitwell.com/dotjs/v1/quests/customer/', {
    headers: { 'Authorization': '3de70020e01fa1a037500ffad95de297' },
    params: { user_id: customerId },
  })
}
