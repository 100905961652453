import { createRouter, createWebHistory } from 'vue-router'
import Index from '../views/Index.vue'
import _last from 'lodash/last'
import eventBus from '../utils/eventBus.js'
import { trackPageview } from '../../shared/serverTrack.js'
import authRoutes from './routes.auth.js'
import teamRoutes from './routes.team.js'
import planRoutes from './routes.plan.js'
import userRoutes from './routes.user.js'
import roomRoutes from './routes.room.js'
import adminRoutes from './routes.admin.js'

const routes = [
  //
  // Landing Pages
  //
  {
    path: '/',
    name: 'home',
    component: Index,
  },

  ...authRoutes,
  ...teamRoutes,
  ...planRoutes,
  ...userRoutes,
  ...roomRoutes,
  ...adminRoutes,

  //
  // Error Page
  //
  {
    path: '/error/',
    name: 'error',
    meta: {
      title: 'Error',
    },
    component: () => import('../views/Error.vue'),
  },

  //
  // Legacy Redirects
  //
  {
    path: '/timer/generate/',
    name: 'roomGenerate.obsolete',
    redirect: { name: 'roomGenerate' },
  },
  {
    path: '/timer/:roomId/',
    name: 'timer.obsolete',
    redirect: ({ params }) => ({ name: 'viewer', params }),
  },
  {
    path: '/timer/:roomId/controller/',
    name: 'controller.obsolete',
    redirect: ({ params }) => ({ name: 'controller', params }),
  },
]

routes.push({
  path: '/:pathMatch(.*)*',
  name: 'NotFound',
  meta: {
    title: 'Not Found',
  },
  component: () => import('../views/NotFound.vue'),
})

const router = createRouter({
  strict: true,
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // make hash navigation work
    if (to.hash) return { el: to.hash }
    if (savedPosition) return savedPosition
    // avoid scroll-to-top on go timer
    if (to.name === 'go-timer') return {}
    // scroll to top after route change
    return { top: 0 }
  },
})

// trailingslah redirect
router.beforeEach((to, from, next) => {
  if (to.path.endsWith('/')) return next()
  let path = to.path.replace(/\/+$/, '') + '/'
  if (to.fullPath.includes('?')) path += '?' + _last(to.fullPath.split('?'))
  next(path)
})

/**
 * Emit event to trigger progress bar
 */
router.beforeEach((to, from, next) => {
  if (to.name) eventBus.$emit('asyncRouteLoadingStart') // Start progress bar
  next()
})

/**
 * Emit event to stop progress bar
 */
router.beforeResolve((to, from, next) => {
  eventBus.$emit('asyncRouteLoadingStop') // Stop progress bar
  next()
})

/**
 * Use afterEach hook to track pageviews
 */
router.afterEach(() => {
  trackPageview()
})

export default router
