<template>
  <router-link
    class="logo rounded inline-flex gap-[0.5em] items-center"
    :class="{
      'hover:underline decoration-white': props.underline && props.dark,
      'hover:underline decoration-teal-500': props.underline && !props.dark,
    }"
    :to="teamId ? { name: 'dashboard', params: { teamId } } : { name: 'team-chooser' }"
  >
    <img
      class="inline w-[1.5em]"
      src="/logo.svg"
      alt="Logo"
      width="30"
      height="30"
    >
    <span
      class="font-semibold whitespace-nowrap"
      :class="{ 'hidden md:inline': props.responsive }"
    >
      <span>stagetimer</span>
      <span :class="{ '!text-teal-600': !props.dark, '!text-white': props.dark }">.io</span>
    </span>
  </router-link>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const props = defineProps({
  underline: Boolean,
  responsive: Boolean,
  dark: Boolean,
})

const teamId = computed(() => route.params.teamId)
</script>
