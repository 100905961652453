import { defineStore } from 'pinia'
import { useTimeset } from './timeset.js'
import { useTimers } from './timers.js'
import { useMessages } from './messages.js'
import { useUser } from './user.js'
import { todFormats } from '@stagetimerio/shared'
import { permissions, planCan } from '@stagetimerio/planutils'
import { isValidTimezone } from '@stagetimerio/timeutils'
import { isBrowserH12 } from '../utils/timeUtils.js'
import * as OOOService from '../utils/OutOfOrderService.js'
import _pick from 'lodash/pick'
import _merge from 'lodash/merge'

const keys = [
  '_id',
  'pid',
  'uid',
  'teamId',
  'name',
  'settings',
  'secrets',
  'createdAt',
  'updatedAt',
]

export const useRoom = defineStore('room', {
  state: () => ({
    _id: null,
    pid: null,
    uid: null,
    teamId: null,
    name: '',
    settings: {},
    secrets: {},
    createdAt: null,
    updatedAt: null,
    plan: null,
    team: null,
  }),
  getters: {
    get: (state) => ({
      ..._pick(state, keys),
      timeset: useTimeset().get,
      timers: useTimers().get,
      messages: useMessages().get,
    }),
    blackout: state => state.settings.blackout,
    limits: state => state.plan?.limits,
    licensed: state => planCan(state.plan, permissions.LICENSED),
    todFormat: state => state.settings.timer?.todFormat || todFormats.AUTO,
    hour12: state => (state.todFormat === todFormats.AUTO ? isBrowserH12() : todFormats.isH12(state.todFormat)),
    hasFeature: state => feature => useUser().hasFeature(state.teamId, feature),
  },
  actions: {
    set (room, t) {
      OOOService.register('room', t)
      sanitizeTimezone(room.settings)
      Object.assign(this, _pick(room, keys))
      useTimeset().set(room.timeset, t)
      useTimers().set(room.timers, t)
      useMessages().set(room.messages, t)
    },
    setName (name, t) {
      if (!OOOService.check('room', t)) return
      this.name = name
    },
    setSecrets (secrets, t) {
      if (!OOOService.check('room', t)) return
      this.secrets = secrets
    },
    updateSettings (settings, t) {
      if (!OOOService.check('room', t)) return
      sanitizeTimezone(settings)
      Object.assign(this.settings, settings)
    },
    setTeam (team) {
      if (team) {
        this.teamId = team.id
        this.team = team
      } else {
        this.teamId = null
        this.team = null
      }
    },
    setPlan (plan) {
      this.plan = plan
    },
    patch (payload) {
      _merge(this, _pick(payload, keys))
    },
  },
})

/**
 * Avoid problems with the legacy 'auto' timezone value
 * @param  {Object} settings
 * @return {Object}
 */
function sanitizeTimezone (settings) {
  settings.timezone = isValidTimezone(settings.timezone) ? settings.timezone : null
  return settings
}
