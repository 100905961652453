<template>
  <section class="container max-w-screen-xl mx-auto px-4 md:px-12 h-[80px]">
    <div class="flex flex-wrap items-center justify-start md:justify-center h-full border-t gap-x-5 gap-y-2 text-sm text-neutral-500">
      <a class="hover:underline whitespace-nowrap" href="/contact/">
        Contact
      </a>
      <a class="hover:underline whitespace-nowrap" href="/imprint/">
        Imprint
      </a>
      <a class="hover:underline whitespace-nowrap" href="/terms-of-service/">
        Terms of Service
      </a>
      <a class="hover:underline whitespace-nowrap" href="/privacy-policy/">
        Privacy Policy
      </a>
      <a class="hover:underline whitespace-nowrap" href="/cookie-policy/">
        Cookie Policy
      </a>
      <a class="hover:underline whitespace-nowrap" href="/security-and-compliance/">
        Security & Compliance
      </a>
      <span class="whitespace-nowrap">
        🇩🇪 Made in Germany
      </span>
    </div>
  </section>
</template>
