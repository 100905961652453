import validateRoomPid from '../utils/validateRoomPid.js'
import { useUser } from '../store/user.js'
import { authForwardRoute } from '../utils/authForwardRoute.js'

export async function awaitUser (to, from, next) {
  const user = useUser()
  await user.init
  return next()
}

export async function requireAuth (to, from, next) {
  const user = useUser()
  if (!user.uid) return next({ name: 'login', query: { next: to.fullPath } })
  return next()
}

export async function requireAdmin (to, from, next) {
  const user = useUser()
  await user.init
  if (!user.admin) return next({ name: 'error', query: { message: 'Authentication required' } })
  return next()
}

export async function validatePid (to, from, next) {
  // ensure that roomPid is always uppercase
  const roomPid = String(to.params.roomPid || '').toUpperCase()
  if (to.params.roomPid !== roomPid) return next({ ...to, params: { roomPid } })

  // validate roomPid
  if (!validateRoomPid(roomPid)) return next({ name: 'roomInvalid', query: { roomPid: roomPid } })
  return next()
}

export async function forwardAuthenticated (to, from, next) {
  const user = useUser()
  await user.init
  if (!user.authenticated) return next()
  const nextRoute = authForwardRoute(to.query)
  return next(nextRoute)
}
