import AuthParent from '../views/auth/Parent.vue'
import { awaitUser, forwardAuthenticated } from './middleware.js'

export default [
  //
  // Auth Pages
  // ^/auth
  //
  {
    path: '/auth/',
    name: 'authParent',
    redirect: { name: 'login' },
    component: AuthParent,
    meta: {
      app: true,
      context: 'auth',
      title: 'Auth',
    },
    beforeEnter: [awaitUser],
    children: [
      {
        path: 'login/',
        name: 'login',
        meta: {
          title: 'Login',
        },
        beforeEnter: [forwardAuthenticated],
        component: () => import('../views/auth/Login.vue'),
      },
      {
        path: 'signup/',
        name: 'signup',
        meta: {
          title: 'Sign Up',
        },
        beforeEnter: [forwardAuthenticated],
        component: () => import('../views/auth/Signup.vue'),
      },
      {
        path: 'logout/',
        name: 'logout',
        meta: {
          title: 'Logout',
        },
        component: () => import('../views/auth/Logout.vue'),
      },
      {
        path: 'forgot-password/',
        name: 'forgot-password',
        meta: {
          title: 'Forgot Password',
        },
        component: () => import('../views/auth/ForgotPassword.vue'),
      },
      {
        path: 'email-verified/',
        name: 'email-verified',
        meta: {
          title: 'Email Verified',
        },
        component: () => import('../views/auth/EmailVerified.vue'),
      },

      // Redirects
      {
        path: 'signin/',
        name: 'signin',
        redirect: { name: 'login' },
      },
      {
        path: 'signout/',
        name: 'signout',
        redirect: { name: 'logout' },
      },
      {
        path: 'paddle-retain-test/',
        name: 'paddle-retain-test',
        component: () => import('../views/auth/PaddleRetain.vue'),
      },
    ],
  },
]
