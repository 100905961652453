<template>
  <div
    v-if="user.authenticated"
    ref="container"
    class="relative"
  >
    <Dropdown
      :dark="props.dark"
      align="right"
      dropdown-class="w-40"
    >
      <template v-slot:button="{ toggle }">
        <button
          data-label="account-dropdown-button"
          class="flex items-center min-w-min"
          :class="props.btnClass"
          @click="toggle"
        >
          <div class="w-5 mr-2">
            <img
              :src="user.photoUrl"
              alt="User Icon"
              class="rounded-full border border-neutral-500 text-transparent w-5 h-5"
              :class="{
                'border-2 border-red-600': user.admin,
                'border border-neutral-500': !user.admin,
              }"
              loading="lazy"
            >
          </div>
          <div
            v-if="props.label !== false"
            class="hidden xl:block text-sm mr-2"
            :class="{ 'font-semibold text-red-600': user.admin }"
          >
            {{ props.label || user.displayName || user.email }}
          </div>
          <FaIcon class="w-4" :icon="faCaretDown" size="sm" />
        </button>
      </template>
      <template v-slot:default>
        <router-link
          data-label="dd-dashboard-btn"
          class="btn text-left text-sm block w-full px-3 py-1"
          :class="{ 'hover:bg-neutral-700': props.dark, 'hover:bg-neutral-100': !props.dark }"
          :to="{ name: 'dashboard', params: { teamId: teamIdWithFallback } }"
        >
          Dashboard
        </router-link>
        <router-link
          data-label="dd-devices-btn"
          class="btn text-left text-sm block w-full px-3 py-1"
          :class="{ 'hover:bg-neutral-700': props.dark, 'hover:bg-neutral-100': !props.dark }"
          :to="{ name: 'devices', params: { teamId: teamIdWithFallback } }"
        >
          Connected Devices
        </router-link>
        <router-link
          data-label="dd-billing-btn"
          class="btn text-left text-sm block w-full px-3 py-1"
          :class="{ 'hover:bg-neutral-700': props.dark, 'hover:bg-neutral-100': !props.dark }"
          :to="{ name: 'billing', params: { teamId: teamIdWithFallback } }"
        >
          Billing
        </router-link>
        <hr
          class="my-1 -mx-1"
          :class="{ 'border-neutral-600': props.dark, 'border-neutral-100': !props.dark }"
        >
        <router-link
          data-label="dd-settings-btn"
          class="btn text-left text-sm block w-full px-3 py-1"
          :class="{ 'hover:bg-neutral-700': props.dark, 'hover:bg-neutral-100': !props.dark }"
          :to="{ name: 'user-settings' }"
        >
          User Settings
        </router-link>
        <router-link
          data-label="dd-logout-btn"
          class="btn text-left text-sm block w-full px-3 py-1"
          :class="{ 'hover:bg-neutral-700': props.dark, 'hover:bg-neutral-100': !props.dark }"
          :to="{ name: 'signout' }"
        >
          Logout
        </router-link>
      </template>
    </Dropdown>
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script setup>
import Dropdown from '../../../shared/components/Dropdown.vue'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useUser } from '../../store/user.js'
import { computed } from 'vue'

const user = useUser()
const props = defineProps({
  teamId: { type: String, default: null },
  label: { type: [String, Boolean], default: undefined },
  btnClass: { type: [String, Array, Object], default: 'btn-beta btn-ghost h-10 py-0 px-2' },
  dark: Boolean,
})

const teamIdWithFallback = computed(() => props.teamId || user.teams[0]?.id)
</script>
